.lia-node-panel {
  .lia-node-section {
    &-header {
      padding-bottom: 0;
    }

    &-body {
      padding: 0;
    }
  }
}

.lia-text-alignment {
  &-left {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left;
  }

  &-center {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &-right {
    align-items: flex-end !important;
    text-align: right;
    justify-content: flex-end !important;
  }
}
